import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCRI8PK5iSHhAzOritJKgIkpYdvDhZOuK4",
  authDomain: "vlan.itisamystery.com",
  databaseURL: "https://test-project-7c723.firebaseio.com",
  projectId: "test-project-7c723",
  storageBucket: "test-project-7c723.appspot.com",
  messagingSenderId: "906354234453",
  appId: "1:906354234453:web:4b984afe14267108d8f0dc",
  measurementId: "G-2PGJESXM3F",
};

firebase.initializeApp(config);

export const Firebase = firebase;

export const firestore = firebase.firestore();
