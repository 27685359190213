import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Collapse } from "@mui/material";

import useStore from "../../stores";
import Toolbar from "../../components/Toolbar";
import GameCardList from "../../components/GameCardList";

export default observer(() => {
  const classes = useStyles();
  const navigate = useNavigate();
  // make grid dimensions w/ breakpoints
  const {
    parties: { subscribe, filteredParties: parties },
    user: {
      session: { jwt },
    },
  } = useStore();

  useEffect(() => {
    subscribe();
  }, [subscribe]);

  useEffect(() => {
    if (!jwt) {
      navigate("/");
    }
  }, []);

  return (
    <div className={classes.root}>
      <Container disableGutters={true} maxWidth="lg">
        <div className={classes.toolbarPadding}>
          <Toolbar />
        </div>
        <Collapse appear in={parties.length > 0}>
          <GameCardList />
        </Collapse>
      </Container>
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.grey[300],
    height: "calc(100vh - 64px)",
    top: 64,
    position: "absolute",
  },
  toolbarPadding: {
    padding: [[0, theme.spacing(2)]],
    paddingBottom: theme.spacing(1),
  },
}));
