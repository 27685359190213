import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useStore from "./stores";

import { Firebase } from "./services/firebase";
import AppBar from "./components/AppBar";
import Drawer from "./components/Drawer";
import Dashboard from "./Views/Dashboard";
import Homescreen from "./Views/Homescreen";
import Settings from "./Views/Settings";
import Doom from "./Views/Doom";
import { alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect } from "react";

export default observer(() => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    user: {
      session: { loading },
    },
    user,
  } = useStore();

  console.log("loading is: ", loading);

  useEffect(() => {
    if (loading === false) {
      navigate("/dashboard");
    }
  }, [loading]);

  return (
    <div className={classes.appContainer}>
      <AppBar />
      <Routes>
        <Route path="/" element={<Homescreen />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/doom" element={<Doom />} />
      </Routes>

      <Drawer />
    </div>
  );
});

const useStyles = makeStyles((theme) => {
  return {
    appContainer: {
      overflow: "overlay",
      height: "100vh",
    },
    "@global": {
      html: {
        overflow: "hidden",
        scrollbarColor: [[theme.palette.primary.light, "transparent"]],
      },
      "*::-webkit-scrollbar": {
        width: theme.spacing(0.75),
        height: theme.spacing(1.5),
      },
      "*::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        // backgroundColor: alpha(theme.palette.colors.primary[50], 0.2),
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },
      "*::-webkit-scrollbar-corner": {
        backgroundColor: "transparent",
      },
      "*:focus": {
        outline: "none",
      },
    },
  };
});
