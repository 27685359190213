import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import makeStyles from '@mui/styles/makeStyles';
import {
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Switch,
  Typography,
  Slide,
} from "@mui/material";

export default observer(() => {
  const classes = useStyles();

  // make grid dimensions w/ breakpoints
  const [state, setState] = useState({ "Night Mode": false });

  useEffect(() => {
    document.querySelector("#root > div").scrollBy(0, Number.MIN_SAFE_INTEGER);
  }, []);

  const handleChange = useCallback(({ target: { name } }) => {
    setState((state) => ({
      [name]: !state[name],
    }));
  }, []);

  return (
    <Container
      disableGutters={true}
      maxWidth={false}
      className={classes.container}
    >
      <Paper className={classes.paper}>
        <Slide in appear direction="left">
          <FormControl component="fieldset" variant="standard">
            <FormLabel component={Typography}>Settings</FormLabel>
            <FormGroup>
              {Object.keys(state).map((name, i) => {
                return (
                  <FormControlLabel
                    key={i}
                    control={
                      <Switch
                        disabled
                        checked={state[name]}
                        onChange={handleChange}
                        name={name}
                      />
                    }
                    label={name}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </Slide>
      </Paper>
    </Container>
  );
});

const useStyles = makeStyles((theme) => ({
  container: {
    // display: "contents",
    backgroundColor: theme.palette.grey[200],
    minHeight: "calc(100vh - 64px)",
    top: 64,
    position: "absolute",
    // overflowY: "overlay",
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    padding: theme.spacing(4),
    height: "calc(100vh - 64px)",
    backgroundColor: theme.palette.grey[200],
  },
  formControl: {
    marginBottom: "20%",
  },
}));
