import { Avatar, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import { AvatarGroup } from "@mui/material";

import { stringToHsl } from "../../../utilities";

export default observer(({ roles = [], gameTypes, className }) => {
  // console.log("users are:", users);
  const classes = useStyles();
  return (
    <AvatarGroup className={classes.avatarGroup} max={5}>
      {Object.values(roles).map((user, i) => {
        return (
          <Tooltip key={i} title={user?.displayName || user}>
            <Avatar
              src={user?.photoURL}
              style={{ backgroundColor: stringToHsl(user) }}
              className={className}
            >
              {user[0]}
            </Avatar>
          </Tooltip>
        );
      })}
    </AvatarGroup>
  );
});

const useStyles = makeStyles((theme) => {
  return {
    avatarGroup: {
      "& .MuiAvatar-root": {
        border: "none",
      },
    },
  };
});
