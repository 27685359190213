import { observer } from "mobx-react-lite";
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  Button,
  Typography,
  Toolbar,
  IconButton,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useStore from "../../stores";
// import { Firebase } from '../../services/firebase';

export default observer(() => {
  const classes = useStyles();
  const {
    drawer: { toggleDrawer },
    user: {
      session: { signedIn },
      signOut,
    },
  } = useStore();

  const navigate = useNavigate();

  return (
    <AppBar position="fixed" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar} variant="dense">
        <IconButton
          edge="start"
          onClick={toggleDrawer}
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          size="large">
          <MenuIcon />
        </IconButton>

        <Typography variant="h4" className={classes.title}>
          VLAN
        </Typography>

        <Button
          disabled={!signedIn}
          onClick={() =>
            signedIn ? signOut().then(() => navigate("/")) : undefined
          }
          color="inherit"
        >
          {signedIn ? "Logout" : ""}
        </Button>
      </Toolbar>
    </AppBar>
  );
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: theme.spacing(8),
    justifyContent: "center",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  title: {
    // background:
    //   "linear-gradient(to right, red, yellow, lime, cyan, violet, cyan, lime, yellow, red)",
    // "-webkit-background-clip": "text",
    // "background-clip": "text",
    backgroundImage:
      "linear-gradient(to right, red, orange, yellow, lime, cyan, indigo, violet)",
    "-webkit-background-clip": "text",
    color: "transparent",
    animation: "$move 3000s linear infinite",
    // "background-size": "400% 100%",
    position: "absolute",
    left: "calc(50vw - 43.835px)",

    // .rainbowText {
    //   font-family:arial black;
    //   font-size:70px;

    //
    //   -webkit-text-fill-color: transparent;
    //   animation: move 35s linear infinite;
    // }
  },
  menu: {
    width: "auto",
  },
  "@keyframes move": {
    to: {
      "background-position": "4500vh",
    },
  },
}));
