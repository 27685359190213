import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import makeStyles from '@mui/styles/makeStyles';
import { Container, CardMedia, Slide } from "@mui/material";

export default observer(() => {
  const classes = useStyles();

  const [captchaComplete, setCaptcha] = useState(false);

  useEffect(() => {
    document.querySelector("#root > div").scrollBy(0, Number.MIN_SAFE_INTEGER);
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      try {
        if (
          document
            .querySelector("iframe")
            ?.contentWindow.document.querySelector("#ok").style.display !==
            "none" &&
          captchaComplete === false
        ) {
          setTimeout(() => setCaptcha(true), 1e3);
        }
      } catch (e) {
        // do nothing
      }
    }, 250);
    return () => clearInterval(id);
  });

  return (
    <Container disableGutters maxWidth={false} className={classes.container}>
      <Slide in appear direction="left">
        <div className={classes.doomCaptcha}>
          <CardMedia
            onLoad={() => console.log("Doom loaded")}
            component="iframe"
            id="doom_captcha"
            src="/doomcaptcha/captcha.html?version=13&amp;countdown=on&amp;enemies=4"
          />
        </div>
      </Slide>
    </Container>
  );
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    padding: [[theme.spacing(1), 0, theme.spacing(1), 0]],
    height: `calc(100vh - ${theme.spacing(8)})`,
    width: "100%",
    backgroundColor: theme.palette.colors[100],
    justifyContent: "center",
    alignItems: "center",
    "& iframe": {
      border: "none",
    },
    top: 64,
    position: "absolute",
  },

  doomCaptcha: {
    marginBottom: "20%",
  },
}));
