import { Grid, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react-lite";

import Autocomplete from "../AutoComplete";

import Respek from "../Respek";

export default observer(({ id }) => {
  const classes = useStyles();

  return (
    <Grid id={id} container direction="column" rowSpacing={2}>
      <Grid item>
        <Respek />
      </Grid>
      <Grid item>
        <Toolbar disableGutters className={classes.toolbar}>
          <Autocomplete className={classes.autoComplete} />
        </Toolbar>
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      minHeight: 0,
      // backgroundColor: theme.palette.grey[300],
      justifyContent: "space-between",
      "& .MuiGrid-container": {
        width: "unset",
      },
    },
    autoComplete: {
      width: "50%",
      height: "80%",
    },
  };
});
