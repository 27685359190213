import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import makeStyles from '@mui/styles/makeStyles';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
  Home as HomeIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useStore from "../../stores";
// import { Firebase } from '../../services/firebase';

export default observer((props) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const {
    drawer: { isOpen, toggleDrawer },
    user: {
      session: { signedIn },
    },
  } = useStore();

  const items = [
    {
      name: "Settings",
      path: "/settings",
      Icon: SettingsIcon,
    },
  ];

  const gameItems = [
    {
      name: "DOOM",
      path: "/doom",
      Icon: PlayArrowIcon,
    },
  ];

  const navigateToItem = useCallback(
    (game) => {
      navigate(game);
      toggleDrawer();
    },
    [toggleDrawer]
  );

  const navigateToHome = useCallback(() => {
    navigate(signedIn ? "/dashboard" : "/");
    toggleDrawer();
  }, [signedIn, toggleDrawer]);
  // const navigateToSettings = useCallback(() => {
  //   navigate('/settings')
  //   toggleDrawer()
  // }, [toggleDrawer])

  return (
    <Drawer anchor={"left"} open={isOpen} onClose={toggleDrawer}>
      <List>
        <ListItem button key={"Home"} onClick={navigateToHome}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItem>
      </List>
      <List>
        {signedIn &&
          gameItems.map(({ name, path, Icon }) => (
            <ListItem button key={name} onClick={() => navigateToItem(path)}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          ))}
      </List>
      <Divider />
      <List className={classes.list}>
        {signedIn &&
          items.map(({ name, path, Icon }) => (
            <ListItem button key={name} onClick={() => navigateToItem(path)}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          ))}
      </List>
    </Drawer>
  );
});

const useStyles = makeStyles((theme) => ({
  list: {
    minWidth: 250,
  },
}));
