import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Fade,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Done as DoneIcon,
  Clear as XIcon,
  Link as LinkIcon,
  FileCopy as CopyIcon,
} from "@mui/icons-material";
import copy from "clipboard-copy";
import HashLoader from "react-spinners/HashLoader";

import useStore from "../../../stores";
import { stringToHsl } from "../../../utilities";

const VALHEIM_PORT = "2456";
const FACTORIO_PORT = "34197";

const PASSWORD = "gnuISnotUNIX";

const GameCard = ({ url, game, gameType, gameTypeStatus }) => {
  const classes = useStyles();
  const { name, started, ready, ipAddress } = gameTypeStatus;

  const {
    user: {
      session: { jwt },
    },
  } = useStore();

  const [performingAction, setPerformingAction] = useState(false);

  const copyToClipboard = useCallback(() => {
    const port = game === "valheim" ? VALHEIM_PORT : FACTORIO_PORT;
    copy(`${ipAddress}:${port}`);
  }, [game, ipAddress]);

  const startOrStopServer = useCallback(
    async (action) => {
      setPerformingAction(true);
      try {
        const headers = new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        });
        const response = await fetch("https://api.itisamystery.com/game", {
          method: "POST",
          headers,
          body: JSON.stringify({
            game,
            gameType,
            action,
            password: PASSWORD,
          }),
        });
        const { success } = await response.json();

        if (!success) {
          throw new Error("Server has exploded!");
        }
      } catch (error) {
        console.error(error);
      }
    },
    [game, gameType, jwt]
  );

  useEffect(() => {
    if (started && ready) {
      setPerformingAction(false);
    } else if (started) {
      setPerformingAction(true);
    } else {
      setPerformingAction(false);
    }
  }, [ready, started]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <Typography className={classes.title} variant="h6" align="left">
            {name}
          </Typography>
        }
        avatar={
          <Avatar style={{ backgroundColor: stringToHsl(gameType) }}>
            {gameType.slice(0, 3)}
          </Avatar>
        }
      />
      <CardMedia>
        <List dense className={classes.list}>
          <ListItem>
            <ListItemText>
              <Typography variant="overline">Server ready</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <>
                <Fade in={ready}>
                  <Avatar
                    className={classes.readyIcon}
                    style={!ready ? { display: "none" } : undefined}
                  >
                    <DoneIcon />
                  </Avatar>
                </Fade>
                <Fade in={!ready}>
                  <Avatar
                    className={classes.notReadyIcon}
                    style={ready ? { display: "none" } : undefined}
                  >
                    <XIcon />
                  </Avatar>
                </Fade>
              </>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="overline">EC2 Instance Up</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <>
                <Fade in={started}>
                  <Avatar
                    className={classes.readyIcon}
                    style={!started ? { display: "none" } : undefined}
                  >
                    <DoneIcon />
                  </Avatar>
                </Fade>
                <Fade in={!started}>
                  <Avatar
                    className={classes.notReadyIcon}
                    style={started ? { display: "none" } : undefined}
                  >
                    <XIcon />
                  </Avatar>
                </Fade>
              </>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button={ready}>
            {ready ? (
              <>
                {game !== "valheim" && game !== "factorio" ? (
                  <>
                    <ListItemText>
                      <Link
                        href={url}
                        variant="overline"
                        className={classes.linkText}
                      >
                        Open in steam
                      </Link>
                    </ListItemText>

                    <Link
                      href={url}
                      variant="overline"
                      className={classes.linkText}
                    >
                      <ListItemSecondaryAction>
                        <Avatar className={classes.linkIcon}>
                          <LinkIcon />
                        </Avatar>
                      </ListItemSecondaryAction>
                    </Link>
                  </>
                ) : (
                  <>
                    <ListItemText
                      onClick={copyToClipboard}
                      className={classes.linkText}
                    >
                      <Typography variant="overline">
                        Copy IP Address
                      </Typography>
                    </ListItemText>
                    <ListItemSecondaryAction
                      style={{ marginRight: 7 }}
                      onClick={copyToClipboard}
                    >
                      <Avatar className={classes.linkIcon}>
                        <CopyIcon />
                      </Avatar>
                    </ListItemSecondaryAction>
                  </>
                )}
              </>
            ) : (
              <ListItemText className={classes.linkTextDisabled}>
                {" "}
              </ListItemText>
            )}
          </ListItem>
        </List>
      </CardMedia>
      <CardContent></CardContent>
      <CardActions disableSpacing>
        <Button
          onClick={() => startOrStopServer(started ? "stop" : "start")}
          endIcon={
            (performingAction || started) && !ready ? (
              <HashLoader
                size={15}
                margin={150}
                color={
                  started ? (performingAction ? "#61DBFB" : "red") : "#61DBFB"
                }
              />
            ) : undefined
          }
          variant="contained"
          className={classes.startButton}
          style={{
            backgroundColor:
              started && !ready
                ? performingAction
                  ? "red"
                  : "green"
                : ready
                ? "red"
                : "green",
          }}
        >
          {ready ? "stop" : performingAction ? "stop" : "start"}
        </Button>
      </CardActions>
    </Card>
  );
};

export default GameCard;

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    // height: 200,
    margin: theme.spacing(2),
    [theme.breakpoints.between("xs", "md")]: {
      minWidth: 310,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 420,
    },
    // backgroundColor: 'red',
  },
  list: {
    width: "100%",
  },
  startButton: {
    marginLeft: "auto",
  },
  readyIcon: {
    backgroundColor: "transparent",
    color: "lightgreen",
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  notReadyIcon: {
    backgroundColor: "transparent",
    color: "red",
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  linkIcon: {
    backgroundColor: "transparent",
    color: "blue",
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  linkText: {
    color: "blue",
  },
  linkTextDisabled: {
    height: theme.spacing(4),
  },
}));
