import { createContext, useContext } from "react";
import user from "./user";
import games from "./games";
import drawer from "./drawer";
import parties from "./parties";
import respek from "./respek";
//
const storeContext = createContext();
//
export const StoreProvider = ({ children }) => (
  <storeContext.Provider value={globalStore}>{children}</storeContext.Provider>
);

export default () => useContext(storeContext);

export const globalStore = new (class GlobalStore {
  constructor(stores) {
    for (const store in stores) {
      this[store] = new stores[store](this);
    }
  }
})({
  user,
  games,
  drawer,
  parties,
  respek,
});
