import {
  Button,
  Dialog,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import { observer } from "mobx-react-lite";
import { firestore } from "../../../services/firebase";
import { useState } from "react";
import useStore from "../../../stores";

export default observer(({ open, onClose }) => {
  const {
    user: {
      session: { displayName, photoURL, uid },
    },
  } = useStore();
  const classes = useStyles();
  const [text, setText] = useState("");

  const handleAddParty = async () => {
    await firestore.collection("parties").add({
      partyId: new Date().toISOString(),
      bannerUrl: "https://example.com/banner.jpg",
      instances: {
        factorio: {
          ready: true,
          started: false,
          ipAddress: "192.168.0.1",
        },
        gmod: {
          ready: false,
          started: true,
          ipAddress: "192.168.0.2",
        },
        minecraft: {
          ready: true,
          started: false,
          ipAddress: "192.168.0.3",
        },
        soldat: {
          ready: false,
          started: true,
          ipAddress: "192.168.0.4",
        },
        valheim: {
          ready: true,
          started: false,
          ipAddress: "192.168.0.5",
        },
      },
      partyName: text,
      roles: {
        [uid]: {
          displayName,
          photoURL,
        },
      },
    });

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.container}>
      <Card>
        <CardHeader title="Name your party" />
        <CardContent>
          <TextField
            autoFocus
            value={text}
            onChange={(event) => {
              setText(event.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter" && text !== "") {
                handleAddParty();
              }
            }}
            placeholder="Super Cool Party"
          />
        </CardContent>

        <CardActions className={classes.cardActions}>
          <Button onClick={onClose}>Close</Button>
          <Button
            disabled={text === ""}
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => {
              handleAddParty();
            }}
          >
            Create
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
});

const useStyles = makeStyles((theme) => {
  return {
    container: {
      "& .MuiDialog-paperWidthSm": { minWidth: 335 },
    },
    createButton: {
      backgroundColor: "orange",
    },
    cardActions: {
      justifyContent: "flex-end",
    },
  };
});
