import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, adaptV4Theme } from "@mui/material";
import {
  ThemeProvider,
  StyledEngineProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@mui/material";
import { StoreProvider } from "./stores";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

const theme = createMuiTheme(
  adaptV4Theme({
    props: {
      MuiUseMediaQuery: { noSsr: true },
    },
    palette: {
      mode: "light",
      primary: {
        main: "#222222",
      },
      secondary: {
        main: "#f7a503",
      },
      colors: {
        500: "#222222",
        400: "#494949",
        300: "#808080",
        200: "#B8B8B8",
        100: "#EFEFEF",
      },
    },
  })
);

ReactDOM.render(
  <StrictMode>
    <CssBaseline />
    <StoreProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </StoreProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
