import { observable, makeObservable, action } from "mobx";
import { firestore } from "../services/firebase";
//
export default class Doubler {
  // bind scope to global store scope
  constructor(_) {
    this._ = _;

    makeObservable(this, {
      games: observable,
      subscribe: action,
    });
  }
  games = {};
  listener;
  subscribe = () => {
    if (this.listener === undefined) {
      this.listener = firestore.collection("games").onSnapshot((snapshot) =>
        snapshot.docChanges().forEach(({ doc, type }) => {
          const game = doc.id;
          const data = doc.data();
          for (const gameType in data) {
            const { ipAddress, ready, started, name } = data[gameType];
            console.log(game, gameType, ready, started, name);
            this.games[game] = this.games[game] || {};
            this.games[game][gameType] = {
              ...this.games[game][gameType],
              name,
              ready,
              started,
              ipAddress,
            };
          }
        })
      );
    }
  };
}
