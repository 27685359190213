import { observer } from "mobx-react-lite";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import makeStyles from "@mui/styles/makeStyles";

import useStore from "../../stores";
import GameCard from "./GameCard";
import Avatars from "./Avatars";

export default observer(function GameCardList() {
  const classes = useStyles();
  const {
    parties: { filteredParties: parties, deleteParty },
  } = useStore();

  return (
    <List disablePadding className={classes.list}>
      {parties.map((party, i) => {
        return (
          <ListItem
            key={party.partyId}
            disableGutters
            disabled={party.disabled}
          >
            <Card elevation={0} className={classes.instanceCard}>
              <CardHeader
                title={party.partyName}
                titleTypographyProps={{
                  fontWeight: 700,
                }}
                action={
                  <Stack direction="row" spacing={1}>
                    <Avatars roles={party.roles} />
                    {i !== 0 ? (
                      <Tooltip title="Delete party">
                        <IconButton onClick={() => deleteParty(party.id)}>
                          <Close />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Stack>
                }
              />
              {Object.entries(party.instances).map(([game, data]) => {
                const gameTypes = Object.entries(data);
                return (
                  <Accordion key={game}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className={classes.accordionSummary}
                    >
                      <Typography
                        variant="h6"
                        fontWeight={400}
                        className={classes.accordionTitle}
                      >
                        {game}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Grid
                        container
                        // spacing={4}
                        // rowSpacing={4}
                        // columnSpacing={4}
                        // columns={16}

                        className={classes.gridContainer}
                      >
                        {gameTypes.map(([gameType, gameTypeStatus]) => {
                          return (
                            <Grid key={gameType} item>
                              <GameCard
                                game={game}
                                gameType={gameType}
                                gameTypeStatus={gameTypeStatus}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Card>
          </ListItem>
        );
      })}
    </List>
  );
});

const useStyles = makeStyles((theme) => ({
  list: {
    padding: [[0, theme.spacing(2)]],

    height: "calc(100vh - 248px)",
    overflowY: "overlay",
  },
  instanceCard: {
    backgroundColor: theme.palette.grey[200],
    width: "100%",
    padding: [[theme.spacing(1)], []],
    "& .MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
    "& .MuiAccordionDetails-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  accordionSummary: {
    backgroundColor: theme.palette.grey[100],
  },
  accordionTitle: {
    textTransform: "capitalize",
    backgroundColor: theme.palette.grey[100],
  },

  accordionDetails: {
    backgroundColor: theme.palette.grey[100],
    paddingBottom: theme.spacing(4),
  },
  gridContainer: {
    width: "100%",
    justifyContent: "center",
    margin: 0,
  },
}));
