import { observable, action, makeAutoObservable } from "mobx";

//
export default class {
  isOpen = false;
  // bind scope to global store scope
  constructor(_) {
    this._ = _;
    this.reset();
    makeAutoObservable(this);
  }
  reset = () => {
    this.setOpen();
  };
  setOpen = (open = true) => {
    this.open = open;
  };
}
