import { makeAutoObservable } from "mobx";
import { Firebase } from "../services/firebase";
const auth = Firebase.auth();
//
export default class {
  // bind scope to global store scope
  constructor(_) {
    this._ = _;

    this.reset();
    makeAutoObservable(this);

    auth.onAuthStateChanged((user) => this.setUser(user));
  }

  reset = () => {
    this.setSession();
  };

  setSession = (session = { loading: true, signedIn: false, jwt: "" }) => {
    this.session = session;
  };

  setUser = async (user) => {
    console.log("User is: ", user);
    if (user) {
      let jwt = "";
      try {
        jwt = await user.getIdToken();
        console.log("JWT IS: ", jwt);
      } catch (error) {
        console.log("JWT retrieval failed");
        console.error(error);
      }

      this.setSession({ ...user, loading: false, signedIn: true, jwt });
      return;
    }
  };

  signIn = () => {
    const googleAuthProvider = new Firebase.auth.GoogleAuthProvider();
    return auth.signInWithPopup(googleAuthProvider);
  };

  signOut = () => auth.signOut().then(this.reset);
}
