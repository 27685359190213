import { useCallback, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Collapse, Grid, IconButton, Typography } from "@mui/material";

import useStore from "../../stores";
import { Close as CloseIcon } from "@material-ui/icons";
import snowstorm from "./snowstorm";
import CreateParty from "../CreateParty";
import { observer } from "mobx-react-lite";

export default observer(() => {
  const classes = useStyles();

  const [respek, setRespek] = useState(0);
  const [targetHit, setTargetHit] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);

  const {
    respek: { setOpen, open },
  } = useStore();

  const increaseRespek = useCallback(() => {
    if (respek >= 60) {
      setTargetHit(true);
      snowstorm(true);
    }
    setRespek((respek) => ++respek);
  }, [respek]);

  const increaseRespekKeydown = useCallback(
    (event) => {
      if (event.keyCode === 70) {
        if (respek >= 60) {
          setTargetHit(true);
          snowstorm(true);
        }
        setRespek((respek) => ++respek);
      }
    },
    [respek]
  );

  useEffect(() => {
    document.addEventListener("keydown", increaseRespekKeydown);
    return () => document.removeEventListener("keydown", increaseRespekKeydown);
  }, [increaseRespekKeydown]);

  useEffect(() => {
    return () => {
      snowstorm(false);
    };
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      // className={classes.root}
    >
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.respek}
          rowSpacing={1}
        >
          {open && (
            <Grid item style={{ width: "100%" }}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="overline" className={classes.respekText}>
                    Respek counter:{" "}
                    <span
                      variant="inherit"
                      style={{ color: targetHit ? "red" : "inherit" }}
                    >
                      {respek}
                    </span>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    className={classes.iconButton}
                    onClick={() => {
                      setOpen(false);
                      snowstorm(false);
                    }}
                  >
                    <CloseIcon className={classes.icon} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item>
            <Grid container columnSpacing={1}>
              <Grid item>
                <Button onClick={increaseRespek} variant="outlined">
                  Press F
                </Button>
              </Grid>
              <Grid item>
                <CreateParty />
              </Grid>
            </Grid>
          </Grid>
          {open && (
            <Grid
              item
              style={{
                paddingTop: !targetHit ? 0 : undefined,
                // paddingTop: 0,
              }}
            >
              <Collapse
                in={targetHit}
                onEntered={() => setAnimationFinished(true)}
              >
                {!animationFinished && (
                  <audio
                    className={classes.audio}
                    src="/media/bewareTheTomcat.mp3"
                    controls
                  />
                )}
                {animationFinished && (
                  <audio
                    className={classes.audio}
                    src="/media/bewareTheTomcat.mp3"
                    controls
                    autoPlay
                  />
                )}
              </Collapse>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme) => ({
  root: { width: "100%", background: "red" },
  respek: {
    marginTop: 8,
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  respekText: {
    verticalAlign: "-webkit-baseline-middle",
  },
  iconButton: {
    // paddingRight: 0,
    marginRight: -6,
  },
  icon: {
    height: 21,
    width: 21,
  },
  audio: {
    width: 206,
    height: 21,
    marginBottom: -5,
    "&::-webkit-media-controls-panel": {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));
