import { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import AddIcon from "@mui/icons-material/Add";
import CreateParty from "./modal";

export default observer(() => {
  const classes = useStyles();

  const [state, setState] = useState({
    modalOpen: false,
  });

  const ref = useRef();

  useEffect(() => {
    if (!state.modalOpen && ref.current) {
      ref.current.blur();
    }
  }, [state.modalOpen]);

  return (
    <>
      <Button
        ref={ref}
        variant="contained"
        color="secondary"
        onClick={() => setState((state) => ({ ...state, modalOpen: true }))}
        startIcon={<AddIcon />}
      >
        Party
      </Button>
      {state.modalOpen && (
        <CreateParty
          open={state.modalOpen}
          onClose={() => {
            document
              .querySelector("#root > div")
              .scrollBy(0, Number.MIN_SAFE_INTEGER);

            setState((state) => ({ ...state, modalOpen: false }));
          }}
        />
      )}
    </>
  );
});

const useStyles = makeStyles((theme) => {
  return {};
});
