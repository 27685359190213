import { observable, makeObservable, action, computed } from "mobx";
import { firestore } from "../services/firebase";
import vlanUsers from "./vlanUsers.json";
//
const ogVlanPartyId = "no party ID, hack";
const ogVlanPartyName = "VLAN Kr3w";

export default class Parties {
  // bind scope to global store scope
  constructor(_) {
    this._ = _;

    makeObservable(this, {
      parties: observable,
      filteredParties: observable,
      setParties: action,
      setfilteredParties: action,
      listenForVlanParty: action,
      partiesWithTags: computed,
    });
  }
  parties = [];
  filteredParties = [];

  listener;
  // this allows the previous architecture's data model to be backwards compatible with the new one
  // by coercing the docs in the games collection into a party object
  hackListener;

  setParties = (parties) => {
    this.parties = parties;
  };

  subscribe = () => {
    if (this.listener === undefined && this.hackListener === undefined) {
      this.listener = firestore.collection("parties").onSnapshot((snapshot) => {
        const parties = [];

        const { docs } = snapshot;

        for (const doc of docs) {
          const data = doc.data();
          parties.push({ id: doc.id, ...data });
        }

        this.setParties(parties);

        const targetPartyIndex = this.filteredParties.findIndex(
          ({ partyName }) => partyName === ogVlanPartyName
        );

        if (targetPartyIndex >= 0) {
          this.setfilteredParties([
            this.filteredParties[targetPartyIndex],
            ...parties,
          ]);
        }
      });
      this.listenForVlanParty();
    }
  };

  deleteParty = (partyId) => {
    return firestore.doc(`parties/${partyId}`).delete();
  };

  listenForVlanParty = () => {
    this.hackListener = firestore.collection("games").onSnapshot((snapshot) => {
      const targetPartyIndex = this.filteredParties.findIndex(
        ({ partyName }) => partyName === ogVlanPartyName
      );

      const newParty = {
        partyId: ogVlanPartyId,
        bannerUrl: "",
        instances: {
          ...(this.filteredParties[targetPartyIndex]?.instances ?? {}),
        },
        partyName: ogVlanPartyName,
        roles: vlanUsers.reduce(
          (accObj, user) => Object.assign(accObj, { [user.displayName]: user }),
          {}
        ),
      };

      snapshot.docChanges().forEach(({ doc }) => {
        const newGame = {};
        const data = doc.data();
        for (const gameType in data) {
          const { ipAddress, ready, started, name } = data[gameType];
          newGame[gameType] = {
            name,
            ready,
            started,
            ipAddress,
          };
        }
        const game = doc.id;
        newParty.instances[game] = { ...newParty.instances[game], ...newGame };
      });

      // it exists already
      if (targetPartyIndex >= 0) {
        const filteredParties = [...this.filteredParties];

        filteredParties[targetPartyIndex] = newParty;

        this.setfilteredParties(filteredParties);

        // does not exist already
      } else {
        this.setfilteredParties([newParty, ...this.parties]);
      }
    });
  };

  setfilteredParties = (parties = []) => {
    const index = parties.findIndex(
      ({ partyName }) => partyName === ogVlanPartyName
    );

    if (index === -1) {
      this.filteredParties = parties;
      return;
    }

    // always swap the vlan crew party to the first element
    [parties[index], parties[0]] = [parties[0], parties[index]];

    parties.forEach((party) => {
      if (party.partyName !== ogVlanPartyName) {
        party.disabled = true;
      }
    });

    this.filteredParties = parties;
  };

  capitalize = (string) => string[0].toUpperCase() + string.slice(1);

  get tags() {
    const tags = [];
    const tagCache = {};
    this.parties.forEach((party) => {
      if (tagCache[party.partyName] === undefined) {
        tags.push({
          name: party.partyName,
          string: `${this.capitalize(party.partyName)} - (Party)`,
        });
        tagCache[party.partyName] = true;
      }

      Object.entries(party.instances).forEach(([game, data]) => {
        if (tagCache[game] === undefined) {
          tags.push({
            name: game,
            string: `${this.capitalize(game)}`,
          });
          tagCache[game] = true;
        }

        const gameTypes = Object.keys(data)
          .filter((gameType) => {
            if (tagCache[gameType] === undefined) {
              tagCache[gameType] = true;

              return true;
            }
            return false;
          })
          .map((gameType) => {
            return {
              name: gameType,
              string: `${this.capitalize(gameType)} - ${this.capitalize(game)}`,
            };
          });
        tags.push(...gameTypes);
      });
    });

    return tags;
  }

  get partiesWithTags() {
    const taggedParties = [];
    this.parties.forEach((party) => {
      const tags = [];
      Object.entries(party.instances).forEach(([game, data]) => {
        tags.push(game);
        const gameTypes = Object.keys(data);
        tags.push(...gameTypes);
      });
      taggedParties.push({ party, tags });
    });

    return taggedParties;
  }
}
