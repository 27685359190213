import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Avatar,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import useStore from "../../stores";

import { useNavigate } from "react-router-dom";

export default observer(() => {
  const classes = useStyles();

  const {
    user: {
      signIn,
      session: { loading, signedIn },
    },
  } = useStore();

  // const navigate = useNavigate();

  // console.log("NAvigate is: ", navigate);

  // useEffect(() => {
  //   if (loading === false || signedIn === true) {
  //     navigate("dashboard");
  //   }
  // }, [loading, signedIn]);

  return (
    <Container disableGutters maxWidth={false} className={classes.container}>
      <Grid
        className={classes.frontLayer}
        direction="column"
        justifyContent="center"
        alignItems="center"
        container
      >
        <Grid item>
          <Card className={classes.card}>
            <>
              <CardHeader
                title="VLAN Command"
                avatar={
                  <Avatar className={classes.reactAvatar} src="./logo512.png" />
                }
                titleTypographyProps={{ variant: "h4" }}
              />

              <CardContent>
                <Typography variant="h6" color="textSecondary" component="p">
                  Launch games for fun and profit?
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <Button
                  className={classes.signInButton}
                  onClick={signIn}
                  variant="contained"
                  color="primary"
                >
                  Sign In
                </Button>
              </CardActions>
            </>
          </Card>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </Container>
  );
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    padding: [[theme.spacing(1), 0, theme.spacing(1), 0]],
    height: `calc(100vh - ${theme.spacing(8)})`,
    backgroundColor: theme.palette.colors[200],
    top: 64,
    position: "absolute",
    width: "100%",
  },
  card: {
    margin: [[0, theme.spacing(1), theme.spacing(8), theme.spacing(1)]],
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      boxShadow: "none",
      borderRadius: 0,
    },
    [theme.breakpoints.up("sm")]: {
      height: "inherit",
    },
    [theme.breakpoints.up("md")]: {
      height: "inherit",
    },
  },

  doomCaptcha: {
    padding: theme.spacing(3),
  },
  signInButton: {
    marginLeft: "auto",
  },
  reactAvatar: {
    animation: "$logo-spin infinite 15s linear",
    filter: "hue-rotate(167deg) saturate(9.5)",
  },
  "@keyframes logo-spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
      scale: "(6)",
    },
  },
}));
