import { observable, action, makeObservable } from "mobx";

//
export default class {
  isOpen = false;
  // bind scope to global store scope
  constructor(_) {
    this._ = _;
    makeObservable(this, {
      toggleDrawer: action,
      isOpen: observable,
    });
  }
  toggleDrawer = () => {
    this.isOpen = !this.isOpen;
  };
}
