/* eslint-disable no-use-before-define */
import { useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import SearchIcon from "@mui/icons-material/Search";
import { Chip, InputAdornment } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import useStore from "../../stores";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: ({ name }) => name,
});

export default observer(() => {
  const {
    parties: { partiesWithTags, tags, setfilteredParties },
  } = useStore();
  const classes = useStyles();

  const filterSet = useRef(false);
  const searchTags = useRef([]);

  // in the filterOptions callback, this gets set to true on the first filtering of search results
  // default to all the parties in the meantime
  useEffect(() => {
    if (
      filterSet.current === false &&
      partiesWithTags.length > 1 &&
      partiesWithTags.findIndex(
        ({ party }) => party.partyName === "VLAN Kr3w"
      ) > 0
    ) {
      setfilteredParties(partiesWithTags.map(({ party }) => party));
      filterSet.current = true;
    }
  }, [partiesWithTags, setfilteredParties]);

  return (
    <Autocomplete
      multiple
      freeSolo
      openOnFocus
      filterSelectedOptions
      className={classes.autoComplete}
      popupIcon={<SearchIcon />}
      options={tags}
      margin={0}
      getOptionLabel={({ string }) => string}
      renderTags={(value, getTagProps) =>
        value.map(({ name }, index) => {
          const tagProps = getTagProps({ index });
          return <Chip variant="outlined" label={name} {...tagProps} />;
        })
      }
      filterOptions={(options, state) => {
        const filteredOptions = filterOptions(options, state);

        return filteredOptions;
      }}
      onChange={(event, value) => {
        const parties = [];

        partiesWithTags.forEach((partyWithTags) => {
          const matched = partyWithTags.tags.filter(
            (tag) =>
              value.find(({ name }) => name === tag) ||
              value.find(({ name }) => name === partyWithTags.party.partyName)
          );
          if (matched.length > 0 || value.length === 0) {
            parties.push(partyWithTags.party);
          }
        });
        setfilteredParties(parties);
        searchTags.current = value;
      }}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          margin="normal"
          placeholder="Search games"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
                {params.InputProps.startAdornment}
              </InputAdornment>
            ),

            className: classes.textField,
          }}
        />
      )}
    />
  );
});

const useStyles = makeStyles((theme) => {
  return {
    textField: {
      // border: "1px solid black",
      borderRadius: 8,
      "& div.MuiOutlinedInput-root": {
        padding: "0px !important",
      },
    },
    searchIcon: {
      marginLeft: theme.spacing(0.5),
    },
    autoComplete: {
      "& .MuiFormControl-marginNormal": {
        margin: 0,
      },
      width: "100%",
    },
  };
});
